import api from '@/lib/api';

export default {
    namespaced: true,
    state() {
        return {
            loading: false,
            lojas: []
        }
    },
    mutations: {
        setLoading(state, loading) {
            state.loading = loading;
        },
        setLojas(state, lojas) {
            state.lojas = lojas;
        }
    },
    actions: {
        async load({ commit, state }) {
            try {
                if (state.loading) {
                    return;
                }
                commit('setLoading', true);
                let { data: lojas } = await api.get('/lojas');
                commit('setLojas', lojas);
            } catch (err) {
                console.error(err);
            }
            commit('setLoading', false)
        },
        async setStatus({ commit, dispatch }, { uuids, status }) {            
            let { data } = await api.post("/lojas/set-status", { uuids, status });
            await dispatch('load');
        },
        async excluir({ commit, dispatch }, uuids ) {
            let { data } = await api.delete("/lojas/delete", {
                data: { uuids },
            });
            await dispatch('load');
        }
    },
    getters: {}
}