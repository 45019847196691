import axios from 'axios';
import { v4 as uuid } from 'uuid';

import { router } from '~/router';


export const api = axios.create({
    baseURL: `${import.meta.env.VITE_APPLICATION_PATH}api`,
    //baseURL: `http://localhost:39219/api`,
    //baseURL: 'https://app.auditaestoque.com.br/api'
});



api.interceptors.request.use(config => {
    if (localStorage.getItem('access_token')) {
        let access_token = localStorage.getItem('access_token');
        if (!config.params) {
            config.params = {};
        }
        config.params.access_token = access_token;
    }
    let params = {
        ...config.params,
        request_id: uuid()
    }
    return {
        ...config,
        params
    };
}, err => {
    
    return Promise.reject(err);
})

api.interceptors.response.use(response => {
    return response;
}, err => {
    if (err?.response?.status == 401) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
        router.replace('/login');
    }
    return Promise.reject(err);
})


export default api;