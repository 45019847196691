import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//import { registerSW } from 'virtual:pwa-register'

import 'bootstrap/scss/bootstrap.scss'
 
const app = createApp(App)
app.use(router)
app.use(store)

app.mount('div.app')

/*
const intervalMS = 60 * 60 * 1000

const updateSW = registerSW({
    onRegistered(r) {
        r && setInterval(() => {
            r.update()
        }, intervalMS)
    }
})

*/