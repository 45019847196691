import api from '@/lib/api';

export const Empresa = {
    namespaced: true,
    state() {
        return {
            loading: false,
            saving: false,
            empresa: {
                nome: ""
            },
            configuracoes: {
                tipo_contagem: "peca_a_peca",
                habilitar_secoes: "0",
                inicio_numeracao_loja: "0",
                fim_numeracao_loja: "0",
                inicio_numeracao_estoque: "0",
                fim_numeracao_estoque: "0",
                leitura_sem_cadastro: "0",
            }
        }
    },
    mutations: {
        setLoading(state, loading) {
            state.loading = loading;
        },
        setSaving(state, saving) {
            state.saving = saving;
        },
        setEmpresa(state, empresa) {
            state.empresa = empresa;
        },
        setConfiguracoes(state, configuracoes) {
            state.configuracoes = {
                ...state.configuracoes,
                ...configuracoes
            };
        }
    },
    actions: {
        async load({ commit, state }) {
            try {
                if (state.loading) {
                    //return;
                }
                commit('setLoading', true);
                let { data: { empresa, configs: configuracoes } } = await api.get('/configuracoes');
                commit('setEmpresa', empresa);
                commit('setConfiguracoes', configuracoes);
                return { empresa, configuracoes};
            } catch (err) {
                console.error(err);
            }
            commit('setLoading', false)
        },
        async save({ commit, state, dispatch }, { empresa: { nome }, configs }) {
            if (state.saving) {
                return
            }
            commit('setSaving', true);
            try {
                await api.post(`/configuracoes/save`, {
                    empresa: {
                        nome
                    },
                    configs
                });
                await dispatch('load');                
            } catch (err) {
                console.error(err);
            }
            commit('setSaving', false);
        }
    },
    getters: {}
}